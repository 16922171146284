.resumeBody{
    background-color: black;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 35% 50%;
    justify-content: space-between;
    padding: 5% 10% 0 10%;
    color:  rgb(173, 173, 173);
    font-family: sans-serif;
    overflow-y: none;
}

.resumeProfile{
    display: inline-block;
}

.resumeContainer{
    background-color: rgb(19, 18, 18);
    padding: 1% 4% 1% 4%;
}
.resumeContainer h2{
    font-size: 20px;
    color: rgb(103, 202, 11);
    font-weight: bold;
    padding: 0 0;
}

.resumeDescription{
    font-size: 13px;
}

.resumeSubHeader{
    padding: 4% 0 4% 0;
    display: flex;
    flex-direction: row;
    font-size: 1vw;
    font-weight: bold;
    color: rgb(103, 202, 11);
}

.headerName{
    width: 28%;
}
.familiarWith{
    width: 20%;
}

.subHeaderLine, .familiarLine{
    background-color: rgb(103, 202, 11);
    height: 1px;
    width: 72%;
    margin-top: 2%;
}

.familiarLine{
    width: 80%;
}

.coreTechs{
    font-size: 12px;
    /* font-weight: bold; */
    font-family: 'Fredoka One';
    word-spacing: 3px;
    line-height: 18px;
}

.workHeader{
    color: rgb(103, 202, 11);
    font-weight: bold;
    font-size: 14px;
}

.workDate{
    font-size: 12px;
}

.briefDescription{
    font-size: 14px;
    font-family: 'Fredoka One';
    padding: 1% 0 1% 0;
}

.workList ul{
    padding: 2% 0 0 4%;
    font-size: 13px;
}

@media only screen and (max-width: 600px) {
.resumeProfile{
    display: none;
}

.resumeContainer h2{
    font-size: 8vw;
    padding: 2% 0 0 0;
}

.resumeSubHeader{
    font-size: 4vw;
}

.headerName{
    width: 50%;
}
.familiarWith{
    width: 35%;
}

.subHeaderLine, .familiarLine{
    background-color: rgb(103, 202, 11);
    height: 1px;
    width: 50%;
    margin-top: 3.3%;
}

.familiarLine{
    width: 65%;
}

.resumeBody{
    display: block;
    padding: 17% 2% 0 3%;
    /* height: 100%; */
    overflow-y: scroll; 
  }
}
