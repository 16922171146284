@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Open+Sans&display=swap');
.projectContainer, .resumeContainer, .homeContainer{
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: black; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.projectBody{
    background-color: black;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 35% 50%;
    justify-content: space-between;
    padding: 5% 10% 0 10%;
    color:  rgb(173, 173, 173);
    font-family: sans-serif;
    overflow-y: none;
}

.featuredHeader, .othersHeader{
    font-size: 10px;  
    color: rgb(103, 202, 11);
    font-weight: 800;
    padding: 0 0 3% 0;
}

.featuredProjects{
    width: 100%;
    height: 35vh;
    background-color: rgb(19, 18, 18);
    border-radius: 5px;
    margin: 0 0 5% 0;
}

.projectGrid{
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}

.gridItem1, .gridItem2{
    width: 100%;
    height: 35vh;
    background-color: rgb(19, 18, 18);
    margin: 0 0 3% 0;
    border-radius: 3px;
}

.gridItem2{
    height: 34vh;
}

@media only screen and (max-width: 600px) {
  .homeContainer{
    display: none;
  }

  .projectBody{
    display: block;
    padding: 20% 2% 0 3%;
    /* height: 100%; */
    overflow-y: scroll; 
  }

  .featuredHeader, .othersHeader{
    font-size: 3vw;  
    color: rgb(103, 202, 11);
    font-weight: 800;
    padding: 0 0 3% 0;
}

.featuredProjects{
  width: 100%;
  height: 27vh;
  background-color: rgb(19, 18, 18);
  border-radius: 5px;
  margin: 0 0 5% 0;
}

.projectGrid{
  display: block;
}

.gridItem1, .gridItem2{
  width: 100%;
  height: 27vh;
  background-color: rgb(19, 18, 18);
  margin: 0 0 3% 0;
  border-radius: 3px;
}
}
