.sideBarContainerShow{
    transform: translateY(-100%);
    transition: transform 1s;
    background-color: black;
    color: white;
    font-family: 'Fredoka One';
    height: 850px;
    width: 90%;
    position: fixed;
    z-index: 600;
    opacity: 0.8;
    text-align: center;
    padding-top: 35%;
    font-size: 28px;
}

.sideBarContainer {
    transform: translateY(0);
    transition: transform 1s;
    background-color: black;
    color: white;
    font-family: 'Fredoka One';
    height: 850px;
    width: 100%;
    position: fixed;
    z-index: 100;
    opacity: 1;
    text-align: center;
    padding-top: 35%;
    font-size: 28px;
}

.sideBarContainer ul {
list-style: none;
}

.sideBarContainerShow ul {
    list-style: none;
    }
 
.certificates {
    color: white;

}