@import url('https://fonts.googleapis.com/css?family=Fredoka+One');

.App{
  background-color: black;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Appnavigation{
  display: none;
}

.cursor{
  width: 10px;
  height: 10px;
  background-color: green;
  position: absolute;
  border-radius: 50%;
  z-index: 600;
}

@media only screen and (max-width: 600px) {
.Appnavigation{
display: block;
}
}