@import url('https://fonts.googleapis.com/css?family=Fredoka+One');
.profile{
    font-family: sans-serif;
    padding: 0 0 0 0;
    color:  rgb(173, 173, 173);
    display: inline-block;
}

.TopImgContainer{
    display: none;
}

.header{
    font-size: 40px;
    font-weight: bold;
    color: rgb(103, 202, 11);
    display: block;
}

.mobileHeader{
    display: none;
}

.header div{
    line-height: 45px;
}

.introduction{
    padding: 4% 0 0 0;
    color: rgb(173, 173, 173);
    font-size: 12px;
    text-align: left;
}

.introduction strong{
    color: rgb(171, 236, 171);
}

.mobileExplore{
    display: none;
}

.linkContainer{
    margin-top: 5%;
    display: block;
}

.links{
    padding: 5% 0 0 0;
    color:  rgb(173, 173, 173);
    font-size: 10px;
    letter-spacing: 3px;
}
.projects{
    display: flex;
    flex-direction: row;
}

.line{
    width: 60px;
    background-color:  rgb(173, 173, 173);
    height: 1px;
    margin: 2% 3% 0 3%;
    transition: width 1s;
}

.extendLine{
    transition: width 1s;
    width: 100px;
    background-color:  rgb(173, 173, 173);
    height: 1px;
    margin: 2% 3% 0 3%;
}

.socialMediaLink{
    /* display: grid;
    grid-template-columns: 25% 25% 25% 25%; */
    display: flex;
    flex-direction: row;
    padding-top: 15%;
    width: 100%;
}

.imgContainer{
    width: 50px;
    height: 50px;
    display: inline-block;
}

.socialMediaLink img{
    width: 100%;
    height: 100%;
    border-radius: 50px;
    /* border-radius: 50%; */
}

.github_twitter_linkedin{
    padding: 3% 0 0 5%;
    width: 100%;
}

.github_twitter_linkedin span{
    margin-left: 1%;
    font-size: 13px;
}

.inspiredBy{
    padding: 4% 0 0 0; 
    font-size: 12px;
    text-align: left;
}

.inspiredBy strong{
    color: rgb(103, 202, 11);
    margin-left: 1%;
}

@media only screen and (max-width: 600px) {
    .Profile{
       padding: 30% 0 0 0;
       display: none;
    }
    .mobileHeader{
        display: block;
        font-size: 8.4vw;
        font-weight: bold;
        color: rgb(103, 202, 11);
        text-align: center;
    }

    .header{
        display: none;
    }

    .TopImgContainer{
        display: block;
        width: 120px;
        height: 120px;
        margin: 2% auto 5% auto;
    }

    .TopImgContainer img{
        width: 100%;
        height: 100%;
        border-radius: 50px;
    }

    .introduction{
        padding: 2% 0 0 0;
        font-size: 4.3vw;
        text-align: center;
    }

.linkContainer{
    display: none;
}

.mobileExplore{
    width: 40%;
    margin: 8% auto;
    border-radius: 3px;
    display: block;
}

.mobileExplore section{
    width: 100%;
    height: inherit;
    font-size: 4vw;
    font-weight: bold;
    background-color: rgb(24, 24, 24);
    color: lightgrey;
    border: 1px solid rgb(24, 24, 24);
    outline: none;
    border-radius: 3px;
    padding: 2% 20%;
    /* text-align: center; */
    display: flex;
}

.btnFlex{
    flex: 1;
}

.imgContainer{
    display: none;
}

.socialMediaLink{
    display: block;
    text-align: center;
}

.github_twitter_linkedin span{
    margin-left: 1%;
    font-size: 4.5vw;
}

.inspiredBy{
    text-align: center;
    font-size: 4vw;
}
}